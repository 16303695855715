import * as React from "react"
import { Content, PageLayout, TopNavigation } from '@atlaskit/page-layout';
import "../styles.css";
import NavBar from "../components/navbar";
import BottomBar from "../components/bottomBar";

const PrivacyPolicy = () => {

    const isBrowser = () => typeof window !== "undefined";
  
    return (
      <PageLayout>
            <TopNavigation
                testId="topNavigation"
                id="product-navigation"
                skipLinkTitle="Product Navigation"
                height={60}
                isFixed={false}
            >
                <NavBar/>
            </TopNavigation>
          <Content testId="content">
            <div style={{ width: "100vw", margin: "auto", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div style={{ maxWidth: "75%", paddingBottom: 100 }}>
                <h1 id="autocompress-privacy-policy">Autocompress Privacy Policy</h1>
                <p>Last modified: February 02, 2023.</p>
                <hr/>
                <h2 id="general-information">General Information</h2>
                <p>Autocompress is owned and operated by Hold My Coffee Ltd. Autocompress (&quot;we&quot;, &quot;us&quot; or &quot;our&quot;) applies this policy to the Subscriber (the &quot;Subscriber&quot;, &quot;user&quot;, you&quot; or &quot;your&quot;). By using Autocompress, you expressly consent to the data handling practices described in this notice.</p>
                <p>We collect no information of those who sign up to Autocompress apart from the name of the repo that Autocompress is installed into and aggregate information on what pages consumers access or visit, and information volunteered by the consumer. The information we collect is used to improve the content of our web pages and the quality of our service, and is not shared with or sold to other organisations for commercial purposes, except to provide products or services you&#39;ve requested, when we have your permission, or under the following circumstances:</p>
                <ul>
                <li>It is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, or as otherwise required by law.</li>
                <li>We transfer information about you if Autocompress is acquired by or merged with another company. In this event, Autocompress will notify you before information about you is transferred and becomes subject to a different privacy policy.</li>
                </ul>
                <h2 id="information-gathering-and-usage">Information Gathering and Usage</h2>
                <p>You may provide to Autocompress personal information if you as an individual, or otherwise, sign up to use Autocompress. Autocompress uses collected information for the following general purposes: products and services provision, billing, identification and authentication, services improvement, contact, and research.</p>
                <h2 id="cookies">Cookies</h2>
                <p>A cookie is a small amount of data, which often includes an anonymous unique identifier, that is sent to your browser from a web site&#39;s computers and stored on your computer&#39;s hard drive.</p>
                <p>Cookies are required to use the Autocompress service. We use cookies to record current session information, but do not use permanent cookies.</p>
                <ul>
                <li>Analytics cookies allow us to track analytics data about usage of Autocompress website.</li>
                </ul>
                <p>You may use your browser settings to disable non-essential cookies.</p>
                <h2 id="data-storage">Data Storage</h2>
                <p>Autocompress uses third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the service. Although Autocompress owns the code, databases, and all rights to the application, you retain all rights to your data.</p>
                <h2 id="source-code">Source Code</h2>
                <ul>
                <li>Autocompress does not store any of the user&#39;s private source code unless given explicit, written permission to access their source code in order to provide support.</li>
                <li>Autocompress does not store Bitbucket credentials. Using Bitbucket apps means we do not access your Bitbucket password and that you can revoke our access at any time.</li>
                <li>Autocompress uses certificate authentication with Bitbucket to obtain a temporary token in order to create branches and open pull requests.</li>
                </ul>
                <h2 id="data-security">Data Security</h2>
                <p>We always put security at the forefront of our services. We cannot, however, ensure or warrant the security of any information you transmit to Autocompress, and you do so at your own risk. We cannot guarantee that such information may not be accessed, disclosed, altered, or destroyed by breach of any of our safeguards, however, we make industry reasonable efforts to ensure the security of our systems.</p>
                <p>If you find a vulnerability, please report it to us immediately at <a href="mailto:pyron@holdmy.coffee">pyron@holdmy.coffee</a>. We ask that you do not publicly share the issue until it has been resolved.</p>
                <h2 id="disclosure">Disclosure</h2>
                <p>Autocompress may disclose personally identifiable information under special circumstances, such as to comply with subpoenas or when your actions violate the Terms of Service.</p>
                <h2 id="changes">Changes</h2>
                <p>Autocompress may periodically update this policy, without further notice to you and this will be reflected by a &quot;last modified&quot; date above. It is your responsibility to check this policy periodically for changes. Your continued use of or access to the Service following the posting of any changes to this policy constitutes acceptance of those changes.</p>
                <p>We will notify you about significant changes in the way we treat personal information by placing a prominent notice on our site.</p>
                <h2 id="questions">Questions</h2>
                <p>Any questions about the Privacy Policy should be addressed to <a href="mailto:pyron@holdmy.coffee">pyron@holdmy.coffee</a>.</p>

            </div>
            <BottomBar/>
            </div>

          </Content>
        </PageLayout>
    )
  }
  
  export default PrivacyPolicy;